import { authRoles } from 'app/auth/authRoles';

export const navigations = [
    {
        key: 1,
        name: 'Dashboard',
        path: '/dashboard',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 2,
        name: 'Marketing',
        path: '/marketing-dashboard',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 3,
        name: 'Companies',
        path: '/companies',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 4,
        name: 'AI Text',
        path: '/ai-text',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 5,
        name: 'AI Ads',
        path: '/ai-ads',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 6,
        name: 'Funding',
        path: '/funding',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 7,
        name: 'Products',
        path: '/products',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 8,
        name: 'Company Details',
        path: '/company-details',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 9,
        name: 'Funders',
        path: '/funders',
        auth: [authRoles.all],
        icon: '',
    },
    {
        key: 10,
        name: 'Devices',
        path: '/device-dashboard',
        auth: [authRoles.all],
        icon: '',
    },
]
