import React, { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from 'app/auth/authRoles';

const Devices = Loadable(lazy(() => import("./deviceDashboard/Devices")));

const securedpathRoutes = [
    {
        path: '/device-dashboard',
        element: <Devices />,
        auth: [authRoles.all],
        level: 1,
    },
];

export default securedpathRoutes;
