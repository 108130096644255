import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { get } from 'lodash'
import { useShoppingCart } from 'use-shopping-cart'
import useSettings from 'app/hooks/useSettings'

const initialState = {
    isAuthenticated: false,
    isOrganisationSetuped: false,
    isInitialised: false,
    user: null,
    sme: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken, userId, smeId = '', roles = [], refreshToken = '', siteId = '') => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        localStorage.setItem('smeId', smeId);
        const rolesUpper = roles.map(role => role.toUpperCase());
        localStorage.setItem('roles', JSON.stringify(rolesUpper));
        axios.defaults.headers.common.Authorization = accessToken;
        axios.defaults.headers.common.refresh_token = refreshToken;
        axios.defaults.headers.common.grant_type = 'refresh_token';
        axios.defaults.headers.common.site_id = siteId;

        // TODO: Temp Remove
        // if (localStorage.getItem("isCheckoutInprogress") === null) {
        //     localStorage.setItem('isCheckoutInprogress', false);
        // }
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('siteId');
        localStorage.removeItem('userId');
        localStorage.removeItem('smeId');
        // localStorage.removeItem('isCheckoutInprogress');
        localStorage.removeItem('roles');
        delete axios.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common.refresh_token;
        delete axios.defaults.headers.common.grant_type;
    }
}

/*eslint indent: [2, 4, {"SwitchCase": 1}]*/
const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                isOrganisationSetuped: get(user, 'smeSetupCompleted', false),
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
                isOrganisationSetuped: get(user, 'smeSetupCompleted', false),
            }
        }
        case 'VERIFY_EMAIL_TOKEN': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                isOrganisationSetuped: false,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        case 'RESEND_VERIFY_EMAIL_TOKEN': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        case 'FORGOT_PASSWORD': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        case 'RESET_PASSWORD': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        case 'SET_DEFAULT_SETTINGS': {
            return {
                ...state,
                isOrganisationSetuped: true,
            }
        }
        case 'GET_SME': {
            const { sme } = action.payload

            return {
                ...state,
                sme,
            }
        }
        case 'UPDATE_SME': {
            const { sme } = action.payload

            return {
                ...state,
                sme,
                isOrganisationSetuped: true,
            }
        }
        case 'GET_PROFILE': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        case 'UPDATE_PROFILE': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    verifyEmailToken: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
    resendVerifyEmailToken: () => Promise.resolve(),
    getSme: () => Promise.resolve(),
    updateSme: () => Promise.resolve(),
    getProfile: () => Promise.resolve(),
    updateProfile: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { clearCart } = useShoppingCart()
    const { siteSettings: { id: siteId } } = useSettings()

    const login = async (email, password) => {
        const response = await axios.post('/auth/sign-in', {
            email,
            password,
            site_id: siteId,
        })
        const { accessToken, refreshToken, user } = get(response, "data.data");
        const { smeId, role } = user;
        user.role = get(user, "role", []).map(role => role.toUpperCase())

        setSession(accessToken, user.id, smeId, role, refreshToken, siteId)

        // Clear the cart when user login
        clearCart();

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (email, firstName, surname, phone, password, jobTitle, urlPostfix, accountType) => {
        const userData = {
            email,
            first_name: firstName,
            surname,
            phone,
            job_title: jobTitle,
            password,
            site_id: siteId,
            account_type: accountType,
        };

        const signUpUrl = urlPostfix ? `/auth/create-user/${urlPostfix}`: '/auth/create-user';
        await axios.post(signUpUrl, userData)

        dispatch({
            type: 'REGISTER',
            payload: {
                user: userData,
            },
        })
    }

    const verifyEmailToken = async (verifyToken) => {
        const response = await axios.get(`/auth/verify-account/${verifyToken}`)
        const { user } = response.data

        dispatch({
            type: 'VERIFY_EMAIL_TOKEN',
            payload: {
                user,
            },
        })

        return response;
    }

    const resendVerifyEmailToken = async (email) => {
        const userData = {
            email: email,
            site_id: siteId,
        };
        const response = await axios.post('/auth/resend-verification', userData)

        dispatch({
            type: 'RESEND_VERIFY_EMAIL_TOKEN',
            payload: {
                user: userData,
            },
        })

        return response;
    }

    const forgotPassword = async (email) => {
        const userData = {
            email: email,
            site_id: siteId,
        };
        const response = await axios.post('/auth/forgot-password', userData)

        dispatch({
            type: 'FORGOT_PASSWORD',
            payload: {
                user: userData,
            },
        })

        return response;
    }

    const resetPassword = async (email, password, token) => {
        const userData = {
            email: email,
            password: password,
            token: token,
            site_id: siteId,
        };
        const response = await axios.post('/auth/reset-password', userData)

        dispatch({
            type: 'RESET_PASSWORD',
            payload: {
                user: userData,
            },
        })

        return response;
    }

    const getSme = async () => {
        const response = await axios.get('sme')
        const { sme } = get(response, "data.data", {});

        dispatch({
            type: 'GET_SME',
            payload: {
                sme,
            },
        })

        return response;
    }

    const updateSme = async (data) => {
        const response = await axios.put('/sme', data)

        dispatch({
            type: 'UPDATE_SME',
            payload: {
                sme: data,
            },
        })

        return response
    }

    const getProfile = async (userId = window.localStorage.getItem('userId')) => {
        const response = await axios.get(`/user/${userId}`)
        let { user } = get(response, "data.data", {});
        user.avatar = get(user, "profilePicture", null)
        user.role = get(user, "role", []).map(role => role.toUpperCase())

        // TODO: Review
        // dispatch({
        //     type: 'GET_PROFILE',
        //     payload: {
        //         user,
        //     },
        // })

        return response;
    }

    const updateProfile = async (userId = window.localStorage.getItem('userId'), data) => {
        const response = await axios.put(`/user/${userId}`, data)
        data.avatar = get(data, "profilePicture", null)

        await getProfile(userId);

        return response;
    }

    const logout = async () => {
        const accessToken = window.localStorage.getItem('accessToken')
        await axios.post('/auth/sign-out', { accessToken })

        // Clear the cart when user logout
        clearCart();

        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const refreshToken = window.localStorage.getItem('refreshToken')
                const userId = window.localStorage.getItem('userId')
                const siteIdFromStorage = window.localStorage.getItem('siteId')
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken, userId, '', [], refreshToken, siteIdFromStorage)
                    const response = await axios.get(`/user/${userId}`)
                    let { user } = get(response, "data.data", {});
                    user.avatar = get(user, "profilePicture", null)
                    user.role = get(user, "role", []).map(role => role.toUpperCase())
                    const { smeId, role, siteId } = user;

                    setSession(accessToken, userId, smeId, role, refreshToken, siteId);

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })

                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [state.isAuthenticated])

    // TODO: Need to fix
    // useEffect(() => {
    //     if (!state.isAuthenticated) {
    //         setSession(null)
    //     }
    // }, [state.isAuthenticated])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                verifyEmailToken,
                logout,
                register,
                resendVerifyEmailToken,
                getSme,
                updateSme,
                getProfile,
                updateProfile,
                forgotPassword,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
